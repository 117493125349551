import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/login'
    }, 
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/main',
        name: 'Main',
        redirect: '/user',
        component: () => import('../views/Main.vue'),
        children: [
            {
                path: '/user',
                name: 'User',
                component: () => import('../views/User.vue')
            },
            {
                path: '/liveuser',
                name: 'liveuser',
                component: () => import('../views/liveuser.vue')
            },
            {
                path: '/yx',
                name: 'yx',
                component: () => import('../views/yx.vue')
            },
            {
                path: '/yxadd',
                name: 'yxadd',
                component: () => import('../views/yxadd.vue')
            },
            {
                path: '/yxedit/:id',
                name: 'yxedit',
                component: () => import('../views/yxedit.vue')
            },
            {
                path: '/type',
                name: 'Type',
                component: () => import('../views/Type/Type.vue')
            },
            {
                path: '/addType',
                name: 'AddType',
                component: () => import('../views/Type/AddType.vue')
            },
            {
                path: '/editType/:id',
                name: 'EditType',
                component: () => import('../views/Type/EditType.vue')
            },
            {
                path: '/article',
                name: 'Article',
                component: () => import('../views/Article/Article.vue')
            },
            {
                path: '/addArticle',
                name: 'AddArticle',
                component: () => import('../views/Article/AddArticle.vue')
            },
            {
                path: '/editArticle/:id',
                name: 'EditArticle',
                component: () => import('../views/Article/EditArticle.vue')
            },
            {
                path: '/voice',
                name: 'Voice',
                component: () => import('../views/Voice/Voice.vue')
            },
            {
                path: '/addVoice',
                name: 'AddVoice',
                component: () => import('../views/Voice/AddVoice.vue')
            },
            {
                path: '/editVoice/:id',
                name: 'EditVoice',
                component: () => import('../views/Voice/EditVoice.vue')
            },
            {
                path: '/video',
                name: 'Video',
                component: () => import('../views/Video/Video.vue')
            },
            {
                path: '/addVideo',
                name: 'AddVideo',
                component: () => import('../views/Video/AddVideo.vue')
            },
            {
                path: '/editVideo/:id',
                name: 'EditVideo',
                component: () => import('../views/Video/EditVideo.vue')
            },
            {
                path: '/image',
                name: 'Image',
                component: () => import('../views/Image.vue')
            },
            {
                path: '/notice',
                name: 'Notice',
                component: () => import('../views/Notice/Notice.vue')
            },
            {
                path: '/addNotice',
                name: 'AddNotice',
                component: () => import('../views/Notice/AddNotice.vue')
            },
            {
                path: '/editNotice/:id',
                name: 'EditNotice',
                component: () => import('../views/Notice/EditNotice.vue')
            },
            {
                path: '/suggest',
                name: 'Suggest',
                component: () => import('../views/Suggest.vue')
            },
            {
                path: '/contribute',
                name: 'Contribute',
                component: () => import('../views/Contribute.vue')
            },
            {
                path: '/system',
                name: 'System',
                component: () => import('../views/System.vue')
            },
            {
                path: '/admin',
                name: 'Admin',
                component: () => import('../views/Admin/Admin.vue')
            },
            {
                path: '/addAdmin',
                name: 'AddAdmin',
                component: () => import('../views/Admin/AddAdmin.vue')
            },
            {
                path: '/editAdmin/:id',
                name: 'EditAdmin',
                component: () => import('../views/Admin/EditAdmin.vue')
            },
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem('token')
    if (to.path == "/login") {
        if (!token) {
            return next()
        } else {
            return next('/main')
        }
    } else {
        if (!token) {
            return next('/login')
        } else {
            next()
        }
    }
})

export default router
