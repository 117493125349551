import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

// const REQUEST_URL = 'https://jfymg.cn/admin'
const REQUEST_URL = 'https://j.jfymg.cn/admin'
// const REQUEST_URL = 'http://jfy.dev.com/admin'
export const __IMAGE__ = REQUEST_URL + '/uploadImage'
export const __VOICE__ = REQUEST_URL + '/uploadVoice'
export const __VIDEO__ = REQUEST_URL + '/uploadVideo'

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')